$small_over: "only screen and ( min-width:481px )";
@import "sanitize.css/sanitize.css";

html {
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	font-weight: 500;
	line-height: 1.7;
	letter-spacing: 0.025em;
}

audio,
canvas,
iframe,
img,
svg,
video,
embed,
object {
	vertical-align: top;
}

h1,
h2,
h3,
h4,
p,
dl,
dt,
dd,
ul,
ol {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

figure {
	margin: 0;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
	transition: all 0.3s ease;
	text-decoration: none;
}

address,
small,
input,
textarea,
select {
	font: inherit;
}

button {
	font: inherit;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}

:focus {
	outline: none;
}

.pc {
	display: none;

	@media #{$small_over} {
		display: inline;
	}
}

.sp {
	@media #{$small_over} {
		display: none;
	}
}

.wrapper {
	position: relative;
	margin: 0 auto;
}

/* スクロールアニメーション用
.anm {
	opacity: 0;
	transition: all 0.5s ease-out;
	&.show {
		opacity: 1;
		transform: translate(0, 0);
	}
	&--up {
		transform: translate(0, 100px);
	}
	&--lr {
		transform: translate(-60px, 0);
	}
	&--rl {
		transform: translate(60px, 0);
	}
}
*/

/* アコーディオン用 サンプル
.acdn-sample {
	&.acdn-active {
		height: 0;
		overflow: hidden;
		transition: all 0.5s ease;
		@media (--min) {
			height: auto;
		}
	}
	&.open {
		height: auto;
	}
}
*/